import {Directive, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {GuardsCheckEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

@Directive({
  selector: 'form',
  standalone: true
})
export class ClearFormStateDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private router: Router, private form: NgForm) {
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter(event => event instanceof GuardsCheckEnd))
      .subscribe(() => this.form.control.markAsPristine());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}

