<h1 mat-dialog-title>
  Fehler-Report oder Hinweise senden:
</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Seite</mat-label>
    <input [(ngModel)]="data.url" matInput name="url" disabled>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Beschreibung</mat-label>
    <textarea [(ngModel)]="data.message" matInput name="message"></textarea>
  </mat-form-field>

  <div style="position: relative">
    <div #imageBox class="image-box">
      <aia-image-annotator [displayWidth]="displayWidth" #annotator
                           [image]="data.image"
                           fontSize="36px"
                           fontFamily="Arial"
                           color="#F00"></aia-image-annotator>
    </div>

    <div class="tools">
      <mat-button-toggle-group vertical (valueChange)="$event && annotator.setTool($event)">
        <mat-button-toggle value="pencil" checked title="Markierung einzeichnen">
          <mat-icon>edit</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="text" title="Hinweis-Text einfügen">
          <mat-icon>format_shapes</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button mat-icon-button [disabled]="!annotator.canUndo" (click)="annotator.undo()" title="Undo">
        <mat-icon>undo</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!annotator.canRedo" (click)="annotator.redo()" title="Redo">
        <mat-icon>redo</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-dialog-close mat-raised-button>Abbruch</button>
  <button (click)="send()" color="primary" mat-dialog-close mat-raised-button>Senden</button>
</div>
