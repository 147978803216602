import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppAuthService} from './app-auth.service';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {AutotrimDirective} from '../directive/autotrim.directive';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgIf} from '@angular/common';
import {ClearFormStateDirective} from '../directive/clear-form-state.directive';
import {FormsModule} from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  standalone: true,
  imports: [MatCardModule, MatToolbarModule, FormsModule, ClearFormStateDirective, NgIf, MatFormFieldModule,
    MatInputModule, AutotrimDirective, MatExpansionModule, MatButtonModule]
})
export class LoginComponent implements OnInit, OnDestroy {
  data = {login: '', password: ''};
  lastError;
  _subscription;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AppAuthService) {
  }

  login() {
    this.lastError = null;
    const back = this.route.snapshot.params.back;

    this.authService.login(this.data.login, this.data.password)
      .pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.lastError = err.error.error;
          return of();
        }
        throw err;
      }))
      .subscribe(() => this.router.navigateByUrl(back || '/'));
  }

  ngOnInit() {
    /*
    const back = this.route.snapshot.params.back;
    this._subscription = this.authService.user$.subscribe((u) => {
      if (this.authService.user) {
        this.router.navigateByUrl(back || '/');
      }
    })
    */
  };

  ngOnDestroy() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }
}
