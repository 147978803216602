import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {GlobalResolver} from '../../api/_global';
import {GlobalData} from '../../model';
import {AppAuthService} from '../auth/app-auth.service';

@Directive({standalone: true, selector: '[needOfficeAuth]'})
export class NeedOfficeAuthDirective implements OnInit {
  private viewRef = null;
  private global$: Observable<GlobalData>;

  constructor(globalResolver: GlobalResolver, private viewContainer: ViewContainerRef,
              private authService: AppAuthService, private templateRef: TemplateRef<any>) {
    this.global$ = globalResolver.global$;
  }

  ngOnInit(): void {
    this.global$.subscribe(({needOfficeAuth}) => {
      if (this.authService.inRole('O365SYNC') && needOfficeAuth) {
        if (this.viewRef) {
          return;
        }
        this.viewContainer.clear();
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
        this.viewRef = null;
      }
    })
  }
}
