import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';

@Component({
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [MatToolbarModule, MatCardModule],
})
export class NotFoundComponent {
  url: string;

  constructor(private router: Router) {
    this.url = this.router.url;
  }
}
