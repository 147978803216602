import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {SentryErrorHandler} from '@sentry/angular-ivy';

@Injectable({providedIn: 'root'})
export class CustomErrorHandler implements ErrorHandler {
  sentry = new SentryErrorHandler({logErrors: true, showDialog: false});

  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      let msg = '';
      if ((<any>error).userMessage) {
        msg = msg + (<any>error).userMessage + '<br/>';
      }
      this.handleErrorResponse(error, msg);
      return;
    }
    return this.sentry.handleError(error);
  }

  private handleErrorResponse(response: HttpErrorResponse, userMessage: string) {
    let msg = '';
    let title = `Server-Fehler ${response.status}`;
    if (response.headers.get('content-type') === 'application/json') {
      const content = response.error;
      const lastMessage = content.detail && content.detail.length ? content.detail[content.detail.length - 1] : {};
      msg = content.type + '\n\n' + content.message;
      if (content.type === 'java.lang.RuntimeException') {
        msg = content.message;
      } else if (content.type.startsWith('javax.persistence.')) {
        msg = lastMessage.type + ' ' + lastMessage.message;
        title = 'Datenbank-Fehler';
      } else if (content.type === 'com.google.inject.extensions.security.NotInRoleException') {
        msg = lastMessage.message
          .replace('invalid role to access public ', '')
          .replace(new RegExp('Long', 'g'), 'L')
          .replace(new RegExp('long', 'g'), 'L')
          .replace(new RegExp('int', 'g'), 'I')
          .replace(new RegExp('java.lang.', 'g'), '')
          .replace(new RegExp('java.util.', 'g'), '');
        title = 'Permission denied';
      }
    }
    this.injector.get(ToastrService).error([userMessage, response.statusText, response.message,
        (response.error ? response.error.message : ''), msg].join('<br/>'),
      title, {timeOut: 0, disableTimeOut: true});
  }
}
