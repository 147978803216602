import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {GlobalResolver} from '../../api/_global';
import {GlobalData} from '../../model';

@Directive({standalone: true, selector: '[inDevMode]'})
export class InDevModeDirective implements OnInit {
  private viewRef = null;
  private global$: Observable<GlobalData>;

  constructor(globalResolver: GlobalResolver, private viewContainer: ViewContainerRef,
              private templateRef: TemplateRef<any>) {
    this.global$ = globalResolver.global$;
  }

  ngOnInit(): void {
    this.global$.subscribe(({devMode}) => {
      if (devMode) {
        if (this.viewRef) {
          return;
        }
        this.viewContainer.clear();
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);

      } else {
        this.viewContainer.clear();
        this.viewRef = null;
      }
    })
  }
}
