import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {publishReplay, refCount} from 'rxjs/operators';
import {GlobalData} from '../model';

@Injectable({providedIn: 'root'})
export class GlobalResolver {

  private global$_: Observable<GlobalData>;

  constructor(private http: HttpClient) {
  }

  get global$() {
    if (this.global$_ == null) {
      this.global$_ = this.http.get<GlobalData>('/api/global')
        .pipe(
          publishReplay(1),
          refCount()
        );
      setTimeout(() => this.global$_ = null, 10000);
    }
    /*
    if (this.global$_ == null) {
      this.global$_ = timer(0, 300_000).pipe(
        switchMap(() => this.http.get<GlobalData>('/api/global')),
        shareReplay(1)
      );
    }
    */
    return this.global$_;
  }

  resolve() {
    return this.global$;
  }
}
