<mat-card appearance="outlined" style="max-width: 600px; margin: 4em auto;">
  <mat-toolbar>
    <h1>Login</h1>
  </mat-toolbar>
  <mat-card-content>
    <form #form="ngForm" class="form">

      <mat-error *ngIf="lastError" style="margin-bottom: 2em">{{lastError}}</mat-error>

      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input #name="ngModel" [(ngModel)]="data.login" autofocus autotrim matInput name="login" required>
        <mat-error *ngIf="name.hasError('required')">
          Eingabe erforderlich
        </mat-error>
        <mat-error *ngIf="form.hasError('invalidLogin')">
          Login ungültig
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input #password="ngModel" [(ngModel)]="data.password" matInput name="password" required type="password">
        <mat-error *ngIf="password.hasError('required')">
          Field required
        </mat-error>
      </mat-form-field>

      <mat-action-row>
        <button (click)="login()" [disabled]="form.invalid" color="primary" mat-raised-button>
          Login
        </button>
      </mat-action-row>
    </form>
  </mat-card-content>
</mat-card>
