import {EventEmitter, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ToogleMenuService {
  toggle = new EventEmitter();

  toogleMenu() {
    this.toggle.emit();
  }
}

