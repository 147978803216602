import {Component} from '@angular/core';
import {AppAuthService} from './auth/app-auth.service';
import {NgxCaptureService} from 'ngx-capture';
import {MatDialog} from '@angular/material/dialog';
import {FeedbackDialogComponent} from './feedback-dialog.component';
import * as StackTrace from 'stacktrace-js';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {InDevModeDirective} from './directive/in-dev-mode.directive';
import {NeedOfficeAuthDirective} from './directive/need-office-auth.directive';
import {CommonModule} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {O365AuthDialogComponent} from './o365-auth-dialog.component';

@Component({
  standalone: true,
  selector: 'userMenu',
  templateUrl: 'user-menu.component.html',
  imports: [CommonModule, FormsModule, RouterModule, MatIconModule, MatButtonModule, MatMenuModule, MatDividerModule,
    InDevModeDirective, NeedOfficeAuthDirective, O365AuthDialogComponent],
  styleUrls: ['user-menu.component.scss']
})
export class UserMenuComponent {
  constructor(public authService: AppAuthService, private captureService: NgxCaptureService,
              private matDialog: MatDialog, private toastr: ToastrService) {
  }

  sendFeedback() {
    const mainElement = window['mainElement'];
    const document = mainElement.ownerDocument;
    const styleEl = document.createElement('style');
    styleEl.innerText = '.header { position:inherit; }';
    document.body.appendChild(styleEl);

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width / window.devicePixelRatio;
      canvas.height = img.height / window.devicePixelRatio;

      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      document.body.removeChild(styleEl);
      this.matDialog
        .open(FeedbackDialogComponent, {
          disableClose: true,
          height: `${window.innerHeight * .9}px`,
          width: `${window.innerWidth * .9}px`,
          data: {
            url: window.location.href,
            user: this.authService.user.login,
            image: canvas.toDataURL('image/png')
          }
        })
        .componentInstance.onSave.subscribe(data => {
        StackTrace.report([], '/api/report-error', JSON.stringify(data)).then(() => {
          this.toastr.success('Bericht gesendet!');
        });
      });
    };
    this.captureService.getImage(mainElement, true)
      .subscribe(imageData => img.src = imageData);
  }

  o365auth() {
    this.matDialog.open(O365AuthDialogComponent, {disableClose: true, width: `${window.innerWidth * .9}px`});
  }
}
