import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';


import 'codemirror/mode/cypher/cypher';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import * as Sentry from '@sentry/angular-ivy';
//
// Sentry.init({
//   dsn: 'https://cad4ef7a66ef4946925b5abdd7173f8a@sentry.io/739849',
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    // @ts-ignore
    if (window.ngRef) {
      // @ts-ignore
      window.ngRef.destroy();
    }
    // @ts-ignore
    window.ngRef = ref;


    try {
      if ('serviceWorker' in navigator) {
        // console.log('install serviceWorker');
        // navigator.serviceWorker.register('/ngsw-worker.js');
        navigator.serviceWorker.getRegistrations()
          .then(function (registrations) {
            for (const registration of registrations) {
              registration.unregister();
            }
          });
      } else {
        console.log('serviceWorker not available');
      }
    } catch (e) {
      console.log(e);
    }
  })
  .catch(err => console.error(err));
