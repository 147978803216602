import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {AppAuthService} from './common/auth/app-auth.service';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'sub-sidenav',
  templateUrl: './sub-sidenav.component.html',
  styleUrls: ['sub-sidenav.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDividerModule, RouterLinkActive, RouterLink]
})
export class SubSidenavComponent implements OnInit {
  @ViewChild('subSideNav') subSideNav: ElementRef;
  @Input() isOpen: string;
  @Input() changeSubSidenav;
  @Input() closeSubSidenav;

  constructor(private router: Router, public authService: AppAuthService) {
    router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.closeSubSidebar();
      }
    });
  }

  ngOnInit(): void {
    this.closeSubSidenav.subscribe(res => this.closeSubSidebar());
    this.changeSubSidenav.subscribe(event => {
      this.isOpen = event;
      if (this.isOpen === 'all') {
        this.subSideNav.nativeElement.style.width = '100%';
      } else {
        this.subSideNav.nativeElement.style.width = '400px';
      }
    });
  }

  closeSubSidebar() {
    this.subSideNav.nativeElement.style.width = '0';
  }
}
