import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {of, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

const jwt = new JwtHelperService();
const STORAGE_NAME = 'mdes_auth-token';

export class AuthUser {
  login: string;
  token: string;
  roles: string[];

  constructor(res: AuthUser) {
    this.token = res.token;
    try {
      const decodeToken = jwt.decodeToken(this.token);
      this.login = decodeToken.sub;
      this.roles = decodeToken.a;
    } catch (e) {
      console.error('failed decoding token:', e);
      throw e;
    }
  }
}

@Injectable({providedIn: 'root'})
export class AppAuthService {
  user: AuthUser;

  user$: Subject<AuthUser> = new ReplaySubject<AuthUser>(1);

  private http: HttpClient;

  constructor(backend: HttpXhrBackend, private router: Router) {
    this.http = new HttpClient(backend);

    this.user$.subscribe(user => {
      if (user) {
        localStorage.setItem(STORAGE_NAME, user.token);
      } else {
        localStorage.removeItem(STORAGE_NAME);
      }
      this.user = user;
    });

    this.check();
    setInterval(() => this.check(), 180000);
  }

  get currentUser() {
    return this.user;
  }

  get currentUserLogin() {
    return this.user != null ? this.user.login : null;
  }

  get currentUserRoles() {
    return this.user != null ? this.user.roles : [];
  }

  inRole(role: string = null) {
    return this.user && this.user.roles && (role == null || this.user.roles.indexOf(role) > -1);
  }

  /*
    public checkIdToken(user) {
      return this.http
        .post<AuthUser>('api/auth/login', {idToken: user.idToken})
        .pipe(
          map(res => new AuthUser(res)),
          tap(res => this.user$.next(res)),
          catchError((err) => {
            this.user$.next(null);
            throw err;
          })
        );
    }
  */
  check() {
    const token = localStorage.getItem(STORAGE_NAME);
    const subscription = this.http
      .get<AuthUser>('api/auth/refresh',
        {headers: new HttpHeaders(token ? {'Authorization': 'Bearer ' + token} : {})})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err) => {
          if (err.status == 401 || err.status == 403) {
            this.router.navigate(['/login']);
            this.user$.next(null);
          }
          return of(null);
        })).subscribe(() => subscription.unsubscribe());
    //shareReplay(1, 60000)
  }

  login(login: string, password: string) {
    localStorage.removeItem(STORAGE_NAME);
    return this.http
      .post<AuthUser>('/api/auth/login', {login, password})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err) => {
          this.user$.next(null);
          throw err;
        })
      );
  }

  logout() {
    this.user$.next(null);
  }
}
