import {Component, EventEmitter, Output} from '@angular/core';
import {AppAuthService} from './common/auth/app-auth.service';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {NgIf} from '@angular/common';

@Component({
  selector: 'main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['main-sidenav.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, RouterLinkActive]
})
export class MainSidenavComponent {

  @Output() onSelectsubmenu = new EventEmitter<string>();
  @Output() closeSubSidebar = new EventEmitter();

  constructor(public authService: AppAuthService) {
  }
}
