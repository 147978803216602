import {Component, HostListener} from '@angular/core';
import {ToogleMenuService} from './toogle-menu.service';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  template: `
    <button mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  `,
  selector: 'toogleMenu',
  styles: [
    'button{margin-right: 10px}',
    '@media screen and (min-width: 900px){ button {   display: none}}'
  ],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class ToogleMenuComponent {

  constructor(private service: ToogleMenuService) {
  }

  @HostListener('click') onClick() {
    this.service.toogleMenu();
  }

}
