import {Component, NgZone, OnInit} from '@angular/core';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {ServerVersionService} from './server-version.service';

@Component({
  selector: 'version-reload', template: '',
  standalone: true
})
export class VersionReloadComponent implements OnInit {
  version: { server?: string, client: string };
  alert: ActiveToast<any> = null;

  constructor(private server: ServerVersionService, private zone: NgZone, private toastr: ToastrService) {
  }

  reload() {
    this.alert = null;
    this.zone.runOutsideAngular(() => location.reload());
  }

  ngOnInit(): void {
    this.server.subscribe(version => {
      this.version = version;
      if (version.server && version.server != version.client && !version.client.endsWith('-dirty')) {
        console.log('version-change! server:', version.server, 'client:', version.client);
        if (!this.alert) {
          this.alert = this.toastr.warning(
            'You are using an outdated Version, please <u>click</u> to reload the application',
            'Warning: Server was updated',
            {disableTimeOut: true});
          this.alert.onTap.subscribe(() => this.reload());
        }
      }
    });
  }
}
