import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppAuthService} from './app-auth.service';

@Component({
  template: '',
  standalone: true
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AppAuthService, private router: Router) {
  }

  ngOnInit() {
    this.authService.logout();
    this.router.navigate(['/login']);
    setTimeout(() => window.location.reload(), 100);
  }
}
