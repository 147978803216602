<div #subSideNav [ngSwitch]="isOpen" class="subSideNav">
  <ng-template ngSwitchCase="rundgang">
    <div class="subSideNav-content">
      <div class="closebtn">
        <button (click)="closeSubSidebar()" mat-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <h2>Rundgänge</h2>
      <mat-divider inset></mat-divider>
      <div class="ul-menu" routerLinkActive="active">
        <ul class="level0">
          <li>Rundgänge
            <ul class="level1" routerLinkActive="active">
              <li><a routerLink="rundgang/geplant-list" routerLinkActive="active">in Planung</a></li>
              <li><a routerLink="rundgang/in-work-list" routerLinkActive="active">in Bearbeitung</a></li>
              <li><a routerLink="rundgang/historie" routerLinkActive="active">Verlauf</a></li>
              <li><a *ngIf="authService.inRole('STATISTIK')" routerLink="rundgang/statistik"
                     routerLinkActive="active">Statistik</a></li>
            </ul>
          </li>
          <li>Antworten
            <ul class="level1">
              <li><a routerLink="rundgang/antwort/historie" routerLinkActive="active">Verlauf</a></li>
              <li><a routerLink="rundgang/antwort/markiert" routerLinkActive="active">Markiert</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="pumpen">
    <div class="subSideNav-content">
      <div class="closebtn">
        <button (click)="closeSubSidebar()" mat-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <h2>Pumpenwartung</h2>
      <mat-divider inset></mat-divider>
      <div class="ul-menu">
        <ul class="level0">
          <li><a routerLink="pw/bauteile" routerLinkActive="active">Bauteile</a></li>
          <li><a routerLink="pw/historie" routerLinkActive="active">Verlauf</a></li>
          <li><a routerLink="pw/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
          <li><a routerLink="pw/lager" routerLinkActive="active">Lager</a></li>
          <li><a routerLink="pw/lagerbauteil" routerLinkActive="active">Lager/Bauteilkits</a></li>
          <li><a routerLink="pw/werkstatt" routerLinkActive="active">Werkstatt</a></li>
          <li><a routerLink="pw/extern" routerLinkActive="active">Extern</a></li>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="sensor">
    <div class="subSideNav-content">
      <div class="closebtn">
        <button (click)="closeSubSidebar()" mat-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <h2>Sensorwartung</h2>
      <mat-divider inset></mat-divider>
      <div class="ul-menu">
        <ul class="level0">
          <li><a routerLink="sensor/sensoren" routerLinkActive="active">Sensoren</a></li>
          <li><a routerLink="sensor/wartungen" routerLinkActive="active">Wartungs-Intervall</a></li>
          <li><a routerLink="sensor/historie" routerLinkActive="active">Verlauf</a></li>
          <li><a routerLink="sensor/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
          <li><a routerLink="sensor/lager" routerLinkActive="active">Lager</a></li>
          <li><a routerLink="sensor/labor" routerLinkActive="active">Laborarbeitsplatz</a></li>
          <li><a routerLink="sensor/extern" routerLinkActive="active">Extern</a></li>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="filter">
    <div class="subSideNav-content">
      <div class="closebtn">
        <button (click)="closeSubSidebar()" mat-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <h2>Filter-Tracking</h2>
      <mat-divider inset></mat-divider>
      <div class="ul-menu">
        <ul class="level0">
          <li><a routerLink="filter/filtergehaeuse" routerLinkActive="active">Filtergehäuse</a></li>
          <li><a routerLink="filter/orte-wechsel" routerLinkActive="active">Wechsel-Intervall</a></li>
          <li><a routerLink="filter/historie" routerLinkActive="active">Verlauf</a></li>
          <li><a routerLink="filter/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
          <li><a routerLink="filter/lager" routerLinkActive="active">Lager</a></li>
          <li><a routerLink="filter/werkstatt" routerLinkActive="active">Werkstatt</a></li>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="all">
    <div class="subSideNav-content">
      <div class="closebtn">
        <button (click)="closeSubSidebar()" mat-button>
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div class="more-box pd-top">
        <div class="small-col">
          <h2>Rundgänge</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li>Rundgänge
                <ul class="level1">
                  <li><a routerLink="rundgang/geplant-list" routerLinkActive="active">in Planung</a></li>
                  <li><a routerLink="rundgang/in-work-list" routerLinkActive="active">in Bearbeitung</a></li>
                  <li><a routerLink="rundgang/historie" routerLinkActive="active">Verlauf</a></li>
                  <li><a *ngIf="authService.inRole('STATISTIK')" routerLink="rundgang/statistik"
                         routerLinkActive="active">Statistik</a></li>
                </ul>
              </li>
              <li>Antworten
                <ul class="level1">
                  <li><a routerLink="rundgang/antwort/historie" routerLinkActive="active">Verlauf</a></li>
                  <li><a routerLink="rundgang/antwort/markiert" routerLinkActive="active">Markiert</a></li>
                </ul>
              </li>
              <li>Stammdaten
                <ul class="level1">
                  <li><a routerLink="administration/rundgang/fragen/zuordnung" routerLinkActive="active">Anlagen</a>
                  </li>
                  <li><a routerLink="administration/lager" routerLinkActive="active">Lager</a></li>
                  <li><a routerLink="administration/isocontainer" routerLinkActive="active">Isocontainer</a></li>
                </ul>
                <ul class="level1">
                  <li><a routerLink="administration/workflow" routerLinkActive="active">Arbeitsabläufe</a></li>
                  <li><a routerLink="administration/bereiche" routerLinkActive="active">Aufgaben-Bereich</a></li>
                  <li><a routerLink="administration/vorlagen" routerLinkActive="active">Rundgangvorlagen</a></li>
                  <li><a routerLink="administration/fragen" routerLinkActive="active">Fragen</a></li>
                  <li><a routerLink="administration/fragen-gruppen" routerLinkActive="active">Fragengruppe</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="small-col">
          <h2>Label-Druck</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li><a routerLink="label" routerLinkActive="active">Label-Druck</a></li>
              <li>Stammdaten
                <ul class="level1">
                  <li><a routerLink="administration/printer" routerLinkActive="active">Barcode-Drucker</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="small-col">
          <h2>Filter-Tracking</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li><a routerLink="filter/filtergehaeuse" routerLinkActive="active">Filtergehäuse</a></li>
              <li><a routerLink="filter/orte-wechsel" routerLinkActive="active">Wechsel-Intervall</a></li>
              <li><a routerLink="filter/historie" routerLinkActive="active">Verlauf</a></li>
              <li><a routerLink="filter/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
              <li><a routerLink="filter/lager" routerLinkActive="active">Lager</a></li>
              <li><a routerLink="filter/werkstatt" routerLinkActive="active">Werkstatt</a></li>
              <li>Stammdaten
                <ul class="level1">
                  <li><a routerLink="administration/filtergehaeuse" routerLinkActive="active">Filter-Gehäuse</a></li>
                  <li><a routerLink="administration/filtertyp" routerLinkActive="active">Filter-Typen</a></li>
                  <li><a routerLink="administration/gruppen" routerLinkActive="active">Chemie-Gruppen</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="small-col">
          <h2>Sensorwartung</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li><a routerLink="sensor/sensoren" routerLinkActive="active">Sensoren</a></li>
              <li><a routerLink="sensor/historie" routerLinkActive="active">Verlauf</a></li>
              <li><a routerLink="sensor/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
              <li><a routerLink="sensor/lager" routerLinkActive="active">Lager</a></li>
              <li><a routerLink="sensor/labor" routerLinkActive="active">Laborarbeitsplatz</a></li>
              <li><a routerLink="sensor/extern" routerLinkActive="active">Extern</a></li>
              <li>Stammdaten
                <ul class="level1">
                  <li><a routerLink="administration/sensoren" routerLinkActive="active">Sensoren</a></li>
                  <li><a routerLink="administration/sensorgruppen"
                         routerLinkActive="active">Sensor-Kalibrierung</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="small-col">
          <h2>Pumpenwartung</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li><a routerLink="pw/bauteile" routerLinkActive="active">Bauteile</a></li>
              <li><a routerLink="pw/historie" routerLinkActive="active">Verlauf</a></li>
              <li><a routerLink="pw/orte" routerLinkActive="active">Eingebaut/Orte</a></li>
              <li><a routerLink="pw/lager" routerLinkActive="active">Lager</a></li>
              <li><a routerLink="pw/lagerbauteil" routerLinkActive="active">Lager/Bauteilkits</a></li>
              <li><a routerLink="pw/werkstatt" routerLinkActive="active">Werkstatt</a></li>
              <li><a routerLink="pw/extern" routerLinkActive="active">Extern</a></li>
              <li>Stammdaten
                <ul class="level1">
                  <li><a routerLink="administration/pumpen" routerLinkActive="active">Pumpen</a></li>
                  <li><a routerLink="administration/daempfer" routerLinkActive="active">Dämpfer</a></li>
                  <li><a routerLink="administration/bauteile" routerLinkActive="active">Bauteile</a></li>
                  <li><a routerLink="administration/lagerbauteil" routerLinkActive="active">Lager/Bauteile</a>
                  </li>
                  <li><a routerLink="administration/gruppen" routerLinkActive="active">Chemie-Gruppen</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="small-col">
          <h2>Stammdaten</h2>
          <mat-divider inset></mat-divider>
          <div class="ul-menu">
            <ul class="level0">
              <li><a routerLink="administration/textblock" routerLinkActive="active">Textblock-Gruppen</a>
              </li>
              <li *ngIf="authService.inRole('ADMIN')">
                <a routerLink="administration/benutzer" routerLinkActive="active">Benutzer</a></li>
              <li *ngIf="authService.inRole('ADMIN')">
                <a routerLink="administration/settings" routerLinkActive="active">Einstellungen</a></li>
              <li><a routerLink="administration/device" routerLinkActive="active">Mobile Geräte</a></li>
              <li><a routerLink="administration/device-group" routerLinkActive="active">Mobile Geräte/Gruppen</a>
              </li>
              <li *ngIf="authService.inRole('ADMIN')">
                <a routerLink="administration/oauth-ms" routerLinkActive="active">O356-Auth</a></li>
              <li *ngIf="authService.inRole('ADMIN')">
                <a routerLink="administration/debug" routerLinkActive="active">Datenbanktools</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>


