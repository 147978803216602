<version-reload></version-reload>

<div [class.hide]="!(loader?.value$|async)">
  <div class="loader-bar">
    <mat-progress-bar [value]="loader?.value$|async" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="loader-spinner">
    <mat-progress-spinner [value]="loader?.value$|async" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>


<div #sideNav class="sideNav" id="sideNav">
  <main-sidenav (onSelectsubmenu)="changeSubSidenav.emit($event)"></main-sidenav>
</div>

<sub-sidenav [changeSubSidenav]="changeSubSidenav" [closeSubSidenav]="closeSubSidenav" [isOpen]="isOpen"></sub-sidenav>
<div #main (mouseover)="closeSubSidenav.emit()" class="main" id="main">
  <mat-toolbar color="primary">
    <toogleMenu></toogleMenu>
    <span class="spacer"></span>
    <userMenu></userMenu>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>
