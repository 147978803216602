import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {interval, Subscription} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';

@Component({
  standalone: true,
  templateUrl: 'o365-auth-dialog.component.html',
  styleUrls: ['o365-auth-dialog.component.scss'],
  imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule, MatDialogModule]
})
export class O365AuthDialogComponent implements OnInit, OnDestroy {
  location: string;
  needOfficeAuth: boolean;
  watch: Subscription;

  constructor(private dialogRef: MatDialogRef<O365AuthDialogComponent>, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.post<{ location: string }>(`api/admin/oauth-ms/start`, {})
      .subscribe(({location}) => this.location = location);

    this.watch = interval(500)
      .pipe(
        mergeMap(() => this.http.get<{ needOfficeAuth: boolean }>(`api/global`)),
        filter(({needOfficeAuth}) => !needOfficeAuth),
      )
      .subscribe(() => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.watch?.unsubscribe();
  }
}
