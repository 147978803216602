<a *inDevMode mat-button style="background-color: red; color:white">
  <mat-icon>donut_small</mat-icon>
  DEV
</a>
<a *needOfficeAuth mat-button style="background-color: red; color:white" (click)="o365auth()">
  <mat-icon>domain_disabled</mat-icon>
  Achtung: O365 Anmeldung Erforderlich
</a>
<a routerLinkActive="active" mat-button [matMenuTriggerFor]="user" *ngIf="authService.currentUser">
  <mat-icon>account_circle</mat-icon>
  {{ authService.currentUser.login }}
  <mat-menu [overlapTrigger]=" false" #user="matMenu" class="full-width-menu">
    <button [disabled]=" true" mat-menu-item *ngFor="let r of authService.currentUser.roles">
      {{r}}
      <mat-divider></mat-divider>
    </button>
    <a class="logoutbtn" routerLinkActive="active" mat-menu-item routerLink="/logout">Logout</a>
  </mat-menu>
</a>
<a mat-button (click)="sendFeedback()">
  <mat-icon>feedback</mat-icon>
</a>
