import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PreloadAllModules, Router, RouterModule, Routes} from '@angular/router';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {LoginComponent} from './common/auth/login.component';
import {LogoutComponent} from './common/auth/logout.component';
import {MainSidenavComponent} from './main-sidenav.component';
import {NotFoundComponent} from './not-found.component';
import {SubSidenavComponent} from './sub-sidenav.component';
import * as Sentry from '@sentry/angular-ivy';
import {CustomErrorHandler} from './common/custom-error.handler';
import {VersionReloadComponent} from './common/version-reload.component';
import {UserMenuComponent} from './common/user-menu.component';
import {ToogleMenuComponent} from './common/toogle-menu.component';
import {LoaderHttpXhrBackend} from './common/loader.http.xhr.backend';
import {HttpClientModule, HttpHandler} from '@angular/common/http';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {CustomDateAdapter} from './common/custom-date-adapter';
import {registerLocaleData} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';

import * as localeDe from '@angular/common/locales/de';
import {NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';

registerLocaleData(localeDe.default);

const ROUTES: Routes = [{
  path: '', pathMatch: 'full', redirectTo: '/rundgang/in-work-list', runGuardsAndResolvers: 'always'
}, {
  path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always'
}, {
  path: 'logout', component: LogoutComponent, runGuardsAndResolvers: 'always'
}, {
  path: 'pw',
  loadChildren: () => import('./pumpenwartung/pumpenwartung.routing.module')
    .then(mod => mod.PumpenwartungRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: 'sensor',
  loadChildren: () => import('./sensorwartung/sensorwartung.routing.module')
    .then(mod => mod.SensorwartungRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: 'rundgang',
  loadChildren: () => import('./rundgang/rundgang.routing.module')
    .then(mod => mod.RundgangRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: 'administration',
  loadChildren: () => import('./stammdaten/admin.routing.module')
    .then(mod => mod.AdminRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: 'label',
  loadChildren: () => import('./label-druck/label.routing.module')
    .then(mod => mod.LabelRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: 'filter',
  loadChildren: () => import('./filterwartung/fiterwartung.routing.module')
    .then(mod => mod.FiterwartungRoutingModule),
  runGuardsAndResolvers: 'always'
}, {
  path: '**', component: NotFoundComponent, runGuardsAndResolvers: 'always'
}
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      enableHtml: true,
      positionClass: 'toast-bottom-right'
    }),
    LoadingBarRouterModule,
    RouterModule.forRoot(ROUTES, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    }),
    MainSidenavComponent, SubSidenavComponent, VersionReloadComponent, UserMenuComponent, ToogleMenuComponent,
    MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatToolbarModule,
    MatNativeDateModule, NgxMatNativeDateModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {provide: HttpHandler, useClass: LoaderHttpXhrBackend},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
//    router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
