import {HttpHandler, HttpRequest, HttpResponse, HttpXhrBackend} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {AppAuthService} from './auth/app-auth.service';
import {ServerVersionService} from './server-version.service';

@Injectable({providedIn: 'root'})
export class LoaderHttpXhrBackend implements HttpHandler {

  constructor(private backend: HttpXhrBackend, private version: ServerVersionService, public authService: AppAuthService) {
  }

  handle<T>(req: HttpRequest<any>) {
    return this.authService.user$.pipe(
      take(1),
      map((user) => user ? req.headers.set('Authorization', 'Bearer ' + user.token) : req.headers),
      map((headers) => headers.set('X-Api-Version', '2')),
      map((headers) => new HttpRequest<T>(req.method, req.url, req.body, {headers, responseType: req.responseType})),
      mergeMap((request) => this.backend.handle(request)),
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.version.update(res.headers.get('x-application-version'));
        }
      })
    );
  }
}

export function buildQuery(query: { [key: string]: any }): string {
  const keys = query ? Object.keys(query).filter(key => query[key] != null) : [];

  if (keys.length > 0) {
    return '?' + keys.map((key) => (encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))).join('&');
  }
  return '';
}
