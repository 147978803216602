<h1 mat-dialog-title>MS-Office 365 Anmeldung</h1>

<div mat-dialog-content>
  <ol>
    <li>
      Öffnen Sie den Link in einem <strong style="text-decoration: underline">Inkognito-Fenster</strong> (rechte
      Maustaste)<br><br>
      <a (click)="$event.button === 2" [href]="location" mat-raised-button  target="_blank">
        <mat-icon>open_in_new</mat-icon>
        neues Fenster</a>
      <br><br>
    </li>
    <li>
      Melden Sie sich im neuen Fenster an MS-Office 365 Account an.<br/>
      <dl>
        <dt>Email:</dt>
        <dd>drsmodas&commat;gfoundries.com (automatisch)</dd>

        <dt>Benutzer:</dt>
        <dd>drsmodas</dd>

        <dt>Passwort:</dt>
        <dd>Gf_8612010</dd>
      </dl>
    </li>
    <li>Berechtigungen Bestätigen</li>
  </ol>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Schließen</button>
</div>
