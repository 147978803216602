import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {AiaImageAnnotatorComponent} from './aia-image-annotator/aia-image-annotator.component';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

class FeedbackReport {
  user: string;
  url: string;
  message: string;
  image: string;
}

@Component({
  standalone: true,
  imports: [
    AiaImageAnnotatorComponent, FormsModule, MatDialogModule, MatInputModule, MatButtonModule, MatButtonToggleModule,
    MatIconModule,
  ],
  templateUrl: 'feedback-dialog.component.html',
  styleUrls: ['feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements AfterViewInit {
  @ViewChild('annotator') annotator: AiaImageAnnotatorComponent;
  @ViewChild('imageBox') imageBox: ElementRef<HTMLDivElement>;
  windowHeight = window.innerHeight;
  onSave = new EventEmitter<FeedbackReport>();
  displayWidth = window.innerWidth * 0.73;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FeedbackReport, private el: ElementRef) {
  }

  @HostListener('window:resize', ['$event'])
  updateWindowSize() {
    this.windowHeight = window.innerHeight;
    if (this.imageBox && this.imageBox.nativeElement) {
      this.imageBox.nativeElement.style.maxHeight = `${Math.max(this.windowHeight - 400, 20)}px`
    }
  }

  ngAfterViewInit(): void {
    this.updateWindowSize();
  }

  send() {
    if (this.annotator.textEntry) {
      this.annotator.textEntry.onBlur().then();
    }
    this.onSave.emit({...this.data, image: this.annotator.getAnnotatedImage('image/png')});
  }
}



