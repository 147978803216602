import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {ServerVersionService} from './common/server-version.service';
import {ToogleMenuService} from './common/toogle-menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sideNavRight') sideNavRight: ElementRef;
  @ViewChild('main') main: ElementRef;
  isOpen: string;
  changeSubSidenav = new EventEmitter<string>();
  closeSubSidenav = new EventEmitter();

  constructor(public version: ServerVersionService, private toogle: ToogleMenuService, public loader: LoadingBarService) {
  }

  ngOnInit(): void {
    this.toogle.toggle.subscribe(() => this.changeSubSidenav.emit('all'));
  }

  ngAfterViewInit(): void {
    window['mainElement'] = this.main.nativeElement;
  }
}
